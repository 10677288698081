import React, { useEffect, useState } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import axios from 'axios';
import MarriageDetails from '../Details/MarriageDetails';

const MarriagesList = ({ parishUser }) => {
  const [marriages, setMarriages] = useState([]);
  const [selectedMarriage, setSelectedMarriage] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    const fetchMarriages = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/marriages`);
        const result = response.data;
        // Ensure result is an array
        setMarriages(Array.isArray(result) ? result : []);
      } catch (error) {
        console.error("Error fetching marriage data:", error);
      }
    };
  
    fetchMarriages();
  }, []);
  

  const filteredMarriages = parishUser
    ? marriages.filter(marriage => marriage.parishUserId === parishUser._id)
    : marriages;

  const handleRowClick = (marriage) => {
    setSelectedMarriage(marriage);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedMarriage(null);
  };

  return (
    <Container>
      <Typography variant="h4">List of Marriages</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Bride's Name</TableCell>
              <TableCell>Groom's Name</TableCell>
              <TableCell>Date of Marriage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMarriages.map((marriage, index) => (
              <TableRow key={index} onClick={() => handleRowClick(marriage)} style={{ cursor: 'pointer' }}>
                <TableCell>{marriage.brideName}</TableCell>
                <TableCell>{marriage.groomName}</TableCell>
                <TableCell>{marriage.marriageDate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedMarriage && (
        <MarriageDetails marriage={selectedMarriage} open={dialogOpen} onClose={handleCloseDialog} />
      )}
    </Container>
  );
};

export default MarriagesList;
