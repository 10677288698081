

import React, { useState, useEffect } from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import axios from 'axios';
import CommunionConfirmationDetails from '../Details/CommunionConfirmationDetails';

const CommunionConfirmationList = ({ parishUser }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/communion_confirmation`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const result = await response.json();
        setData(Array.isArray(result) ? result : []);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  

  const filteredData = parishUser?._id
    ? data.filter(entry => entry.parishUserId === parishUser._id)
    : data;

  const handleRowClick = (entry) => {
    setSelectedEntry(entry);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedEntry(null);
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container>
      <Typography variant="h4">Communion-Confirmation Records</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Sex</TableCell>
              <TableCell>Communion Date</TableCell>
              <TableCell>Confirmation Date</TableCell>
              <TableCell>Blessed By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((entry, index) => (
              <TableRow key={index} onClick={() => handleRowClick(entry)} style={{ cursor: 'pointer' }}>
                <TableCell>{entry.name}</TableCell>
                <TableCell>{entry.sex}</TableCell>
                <TableCell>{entry.communionDate}</TableCell>
                <TableCell>{entry.confirmationDate}</TableCell>
                <TableCell>{entry.blessedBy}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedEntry && (
        <CommunionConfirmationDetails open={dialogOpen} onClose={handleCloseDialog} communionConfirmation={selectedEntry} />
      )}
    </Container>
  );
};

export default CommunionConfirmationList;
