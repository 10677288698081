import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography } from '@mui/material';

const DeathDetails = ({ death, open, onClose }) => {
  if (!death) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Death Details</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          {/* Tomb Number and Name */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Tomb Number:</strong> {death.tombNumber}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Name:</strong> {death.name}</Typography>
          </Grid>

          {/* Date of Birth */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Date of Birth:</strong> {death.dob}</Typography>
          </Grid>

          {/* Address */}
          <Grid item xs={12}>
            <Typography variant="h6">Address</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1"><strong>Line 1:</strong> {death.addressLine1}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1"><strong>Line 2:</strong> {death.addressLine2}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1"><strong>Line 3:</strong> {death.addressLine3}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1"><strong>Google Map Link:</strong> <a href={death.mapLink} target="_blank" rel="noopener noreferrer">{death.mapLink}</a></Typography>
          </Grid>

          {/* Family Details */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Name of the Father:</strong> {death.fatherName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Name of the Mother:</strong> {death.motherName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Name of Husband or Wife:</strong> {death.spouseName}</Typography>
          </Grid>

          {/* Death and Burial Information */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Death Place:</strong> {death.deathPlace}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Death Date:</strong> {death.deathDate}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Burial Place:</strong> {death.burialPlace}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Burial Date:</strong> {death.burialDate}</Typography>
          </Grid>

          {/* Ministry */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Ministry:</strong> {death.ministry}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeathDetails;
