import React, { useEffect, useState } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import axios from 'axios';
import DeathDetails from '../Details/DeathDetails';

const DeathList = ({ parishUser }) => {
  const [deaths, setDeaths] = useState([]);
  const [selectedDeath, setSelectedDeath] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    const fetchDeaths = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/death`);
        console.log("Response data:", response.data);
        setDeaths(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error("Error fetching death data:", error);
      }
    };
  
    fetchDeaths();
  }, []);
  

  const filteredDeaths = parishUser
    ? deaths.filter(death => death.parishUserId === parishUser._id)
    : deaths;

  const handleRowClick = (death) => {
    setSelectedDeath(death);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedDeath(null);
  };

  return (
    <Container>
      <Typography variant="h4">List of Tomb Records</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tomb Number</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Date of Birth</TableCell>
              <TableCell>Death Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDeaths.map((death, index) => (
              <TableRow key={index} onClick={() => handleRowClick(death)} style={{ cursor: 'pointer' }}>
                <TableCell>{death.tombNumber}</TableCell>
                <TableCell>{death.name}</TableCell>
                <TableCell>{death.dob}</TableCell>
                <TableCell>{death.deathDate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedDeath && (
        <DeathDetails death={selectedDeath} open={dialogOpen} onClose={handleCloseDialog} />
      )}
    </Container>
  );
};

export default DeathList;
