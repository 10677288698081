import React, { useEffect, useState } from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import BaptismDetails from '../Details/BaptismDetails';
import { differenceInYears } from 'date-fns';

const BaptismList = ({ parishUser }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  // Fetch Baptism Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/baptisms`);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const result = await response.json();
        setData(Array.isArray(result) ? result : []);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message || 'An unknown error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Calculate Age
  const calculateAge = (birthDate) => {
    try {
      return birthDate ? differenceInYears(new Date(), new Date(birthDate)) : 'N/A';
    } catch {
      return 'N/A';
    }
  };

  // Filter data based on parishUser
  const filteredData = Array.isArray(data)
    ? parishUser?._id
      ? data.filter((entry) => entry.parishUserId === parishUser._id)
      : data
    : [];

  // Handle row click to show details
  const handleRowClick = (entry) => {
    setSelectedEntry({
      ...entry,
      age: calculateAge(entry.birthDate),
    });
    setDialogOpen(true);
  };

  // Close the dialog
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedEntry(null);
  };

  // Show loading state
  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  // Show error state
  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error}
      </Typography>
    );
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        Baptism Records
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Sex</TableCell>
              <TableCell>Age</TableCell>
              <TableCell>Baptism Date</TableCell>
              <TableCell>Blessed By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData.map((entry, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleRowClick(entry)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>{entry.name}</TableCell>
                  <TableCell>{entry.sex}</TableCell>
                  <TableCell>{calculateAge(entry.birthDate)}</TableCell>
                  <TableCell>{entry.baptismDate || 'N/A'}</TableCell>
                  <TableCell>{entry.blessedBy || 'N/A'}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No records found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedEntry && (
        <BaptismDetails
          open={dialogOpen}
          onClose={handleCloseDialog}
          baptism={selectedEntry}
        />
      )}
    </Container>
  );
};

export default BaptismList;
