import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer } from '@mui/material';

export default function OurDevotees({parishUser}) {
  const [submissions, setSubmissions] = useState([]);
  const [families, setFamilies] = useState([]);
  const [anbiams, setAnbiams] = useState([]);

  useEffect(() => {
    const fetchAnbiams = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/anbiams');
        const data = await response.json();
        setAnbiams(parishUser ? data.filter(anbiam => anbiam.parishName === parishUser.name) : data);
      } catch (error) {
        console.error('Error fetching anbiams:', error);
      }
    };
    fetchAnbiams();
  }, [parishUser]);
  

  // Filter anbiams based on parishUser's parish name
  var filteredAnbiams = anbiams
  if(parishUser){
    filteredAnbiams = anbiams.filter(anbiam => anbiam.parishName === parishUser.name);
  }

  useEffect(() => {
    const fetchFamilies = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/api/families');
        // Check if response.data is an array before setting state
        const familiesData = Array.isArray(response.data) ? response.data : [];
        setFamilies(familiesData);
      } catch (error) {
        console.error("There was an error fetching the families!", error);
        setFamilies([]); // Ensure families is always an array
      }
    };
    fetchFamilies();
  }, []);
  
  
  // Filter families based on anbiam
  const filteredFamilies = Array.isArray(families)
  ? families.filter(family => 
      filteredAnbiams.some(anbiam => anbiam._id === family.anbiamId)
    )
  : [];

  

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/devotees');
        const data = await response.json();
        // Check if data is an array before setting state
        const devoteesData = Array.isArray(data) ? data : [];
        setSubmissions(devoteesData);
      } catch (error) {
        console.error('Error fetching devotees:', error);
        setSubmissions([]); // Ensure submissions is always an array
      }
    };
    fetchSubmissions();
  }, []);
  

  // Filter submissions based on familyId matching any _id in filteredFamilies
  const filteredSubmissions = Array.isArray(submissions)
  ? submissions.filter(submission =>
      filteredFamilies.some(family => family._id === submission.familyId)
    )
  : [];



  if(!parishUser)
    {
      filteredSubmissions = submissions
    }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Our Devotees
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Family Card Number</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Birth Place</TableCell>
              <TableCell>Birth Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSubmissions.map((submission) => (
              <TableRow key={submission._id} component={Link} to={`/devotee/${submission._id}`}>
                <TableCell>{submission.familyCardNumber || 'N/A'}</TableCell>
                <TableCell>{submission.name || 'N/A'}</TableCell>
                <TableCell>{submission.phoneNumber || 'N/A'}</TableCell>
                <TableCell>{submission.birthPlace || 'N/A'}</TableCell>
                <TableCell>{submission.birthDate || 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
