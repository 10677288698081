import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer, Select, MenuItem, FormControl, InputLabel, TextField } from '@mui/material';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';  // Import the plugin

dayjs.extend(isBetween);  // Register the plugin

export default function BirthdayList({ parishUser }) {
  const [devotees, setDevotees] = useState([]);
  const [filteredDevotees, setFilteredDevotees] = useState([]);
  const [filterType, setFilterType] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [submissions, setSubmissions] = useState([]);
  const [families, setFamilies] = useState([]);
  const [anbiams, setAnbiams] = useState([]);

  useEffect(() => {
    const fetchAnbiams = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL+'/api/anbiams');
        const data = await response.json();
        setAnbiams(data);
      } catch (error) {
        console.error('Error fetching anbiams:', error);
      }
    };

    fetchAnbiams();
  }, []);

  // Filter anbiams based on parishUser's parish name
  var filteredAnbiams = anbiams
  if(parishUser){
    filteredAnbiams = anbiams.filter(anbiam => anbiam.parishName === parishUser.name);
  }

  useEffect(() => {
    // Fetch families from backend
    axios.get('/api/families')
      .then(response => {
        setFamilies(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the families!", error);
      });

  }, []);
  
  // Filter families based on anbiam
  const filteredFamilies = families.filter(family => 
    filteredAnbiams.some(anbiam => anbiam._id === family.anbiamId)
  );

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL+'/api/devotees');
        const data = await response.json();
        setSubmissions(data);
      } catch (error) {
        console.error('Error fetching devotees:', error);
      }
    };

    fetchSubmissions();
  }, []);

  // Filter submissions based on familyId matching any _id in filteredFamilies
  var filteredSubmissions =submissions
  if(parishUser)
  {
    filteredSubmissions = submissions.filter(submission =>
      filteredFamilies.some(family => family._id === submission.familyId)
    );
  }
  


  if(!parishUser)
    {
      filteredSubmissions = submissions
    }

  useEffect(() => {
    let filtered = filteredSubmissions;
    

    const today = dayjs();
    const startOfWeek = today.startOf('week');
    const endOfWeek = today.endOf('week');
    const startOfMonth = today.startOf('month');
    const endOfMonth = today.endOf('month');

    switch (filterType) {
      case 'thisWeek':
        filtered = filtered.filter(devotee => dayjs(devotee.birthDate).isBetween(startOfWeek, endOfWeek, null, '[]'));
        break;
      case 'upcomingWeek':
        filtered = filtered.filter(devotee => dayjs(devotee.birthDate).isBetween(endOfWeek.add(1, 'day'), endOfWeek.add(7, 'days'), null, '[]'));
        break;
      case 'previousWeek':
        filtered = filtered.filter(devotee => dayjs(devotee.birthDate).isBetween(startOfWeek.subtract(7, 'days'), endOfWeek.subtract(7, 'days'), null, '[]'));
        break;
      case 'currentMonth':
        filtered = filtered.filter(devotee => dayjs(devotee.birthDate).isBetween(startOfMonth, endOfMonth, null, '[]'));
        break;
      case 'previousMonth':
        filtered = filtered.filter(devotee => dayjs(devotee.birthDate).isBetween(startOfMonth.subtract(1, 'month'), endOfMonth.subtract(1, 'month'), null, '[]'));
        break;
      case 'nextMonth':
        filtered = filtered.filter(devotee => dayjs(devotee.birthDate).isBetween(startOfMonth.add(1, 'month'), endOfMonth.add(1, 'month'), null, '[]'));
        break;
      case 'custom':
        if (startDate && endDate) {
          filtered = filtered.filter(devotee => dayjs(devotee.birthDate).isBetween(dayjs(startDate), dayjs(endDate), null, '[]'));
        }
        break;
      default:
        break;
    }
    setFilteredDevotees(filtered);
  }, [filterType, startDate, endDate, parishUser, devotees]);

  const calculateAge = (birthDate) => {
    return dayjs().diff(dayjs(birthDate), 'year');
  };

  // Function to get anbiamName for a devotee
const getAnbiamName = (devotee, families, anbiams) => {
    // Find the family with the same _id as the devotee's familyId
    const family = families.find(family => family._id === devotee.familyId);
    
    // If family is found, get the anbiamId from the family
    if (family && family.anbiamId) {
      // Find the anbiam with the same _id as the family's anbiamId
      const anbiam = anbiams.find(anbiam => anbiam._id === family.anbiamId);
      
      // Return the anbiamName if the anbiam is found
      return anbiam ? anbiam.anbiamName : 'N/A';
    }
  
    return 'N/A'; // Return 'N/A' if no match is found
  };
  

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Birthday List
      </Typography>
      <FormControl variant="outlined" style={{ marginBottom: 20, minWidth: 200 }}>
        <InputLabel>Date Filter</InputLabel>
        <Select
          value={filterType}
          onChange={(e) => setFilterType(e.target.value)}
          label="Date Filter"
        >
          <MenuItem value="thisWeek">This Week</MenuItem>
          <MenuItem value="upcomingWeek">Upcoming Week</MenuItem>
          <MenuItem value="previousWeek">Previous Week</MenuItem>
          <MenuItem value="currentMonth">Current Month</MenuItem>
          <MenuItem value="previousMonth">Previous Month</MenuItem>
          <MenuItem value="nextMonth">Next Month</MenuItem>
          <MenuItem value="custom">Custom</MenuItem>
        </Select>
      </FormControl>

      {filterType === 'custom' && (
        <div style={{ display: 'flex', gap: '1rem', marginBottom: 20 }}>
          <TextField
            label="Start Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <TextField
            label="End Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Age</TableCell>
              <TableCell>Anbiam</TableCell>
              <TableCell>Date of Birth</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDevotees.map((devotee) => (
              <TableRow key={devotee._id}>
                <TableCell>{devotee.name || 'N/A'}</TableCell>
                <TableCell>{calculateAge(devotee.birthDate) || 'N/A'}</TableCell>
                <TableCell>{getAnbiamName(devotee, families, anbiams)}</TableCell>
                <TableCell>{devotee.birthDate || 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
