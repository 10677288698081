import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography } from '@mui/material';

const MarriageDetails = ({ marriage, open, onClose }) => {
  if (!marriage) return null;

  //TODO operator can edit

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Marriage Details</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          {/* Place and Date of Marriage */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Name of Place:</strong> {marriage.placeName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Date of Marriage:</strong> {marriage.marriageDate}</Typography>
          </Grid>

          {/* Bride Details */}
          <Grid item xs={12}>
            <Typography variant="h6">Bride Details</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Name:</strong> {marriage.brideName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Date of Birth:</strong> {marriage.brideDOB}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Marital Status:</strong> {marriage.brideMaritalStatus}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1"><strong>Address:</strong> {marriage.brideAddressLine1}, {marriage.brideAddressLine2}, {marriage.brideAddressLine3}</Typography>
            <Typography variant="body1"><strong>Google Map Link:</strong> <a href={marriage.brideMapLink} target="_blank" rel="noopener noreferrer">{marriage.brideMapLink}</a></Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Father's Name:</strong> {marriage.brideFatherName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Mother's Name:</strong> {marriage.brideMotherName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Witness Name:</strong> {marriage.brideWitnessName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1"><strong>Witness Address:</strong> {marriage.brideWitnessAddressLine1}, {marriage.brideWitnessAddressLine2}, {marriage.brideWitnessAddressLine3}</Typography>
            <Typography variant="body1"><strong>Google Map Link:</strong> <a href={marriage.brideWitnessMapLink} target="_blank" rel="noopener noreferrer">{marriage.brideWitnessMapLink}</a></Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Banns:</strong> {marriage.brideBanns}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Impediment:</strong> {marriage.brideImpediment}</Typography>
          </Grid>

          {/* Groom Details */}
          <Grid item xs={12}>
            <Typography variant="h6">Groom Details</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Name:</strong> {marriage.groomName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Date of Birth:</strong> {marriage.groomDOB}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Marital Status:</strong> {marriage.groomMaritalStatus}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1"><strong>Address:</strong> {marriage.groomAddressLine1}, {marriage.groomAddressLine2}, {marriage.groomAddressLine3}</Typography>
            <Typography variant="body1"><strong>Google Map Link:</strong> <a href={marriage.groomMapLink} target="_blank" rel="noopener noreferrer">{marriage.groomMapLink}</a></Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Father's Name:</strong> {marriage.groomFatherName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Mother's Name:</strong> {marriage.groomMotherName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Witness Name:</strong> {marriage.groomWitnessName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1"><strong>Witness Address:</strong> {marriage.groomWitnessAddressLine1}, {marriage.groomWitnessAddressLine2}, {marriage.groomWitnessAddressLine3}</Typography>
            <Typography variant="body1"><strong>Google Map Link:</strong> <a href={marriage.groomWitnessMapLink} target="_blank" rel="noopener noreferrer">{marriage.groomWitnessMapLink}</a></Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Banns:</strong> {marriage.groomBanns}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Impediment:</strong> {marriage.groomImpediment}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MarriageDetails;
