import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Link, Box, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';

const FamilyList = ({ parishUser }) => {
  const [families, setFamilies] = useState([]);
  const [anbiams, setAnbiams] = useState([]);

  // Fetch Anbiams
  useEffect(() => {
    const fetchAnbiams = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/anbiams`);
        const data = await response.json();
        setAnbiams(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Error fetching anbiams:', error);
      }
    };
    fetchAnbiams();
  }, []);

  // Fetch Families
  useEffect(() => {
    const fetchFamilies = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/families`);
        setFamilies(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error("There was an error fetching the families!", error);
      }
    };
    fetchFamilies();
  }, []);

  // Filter anbiams based on parishUser's parish name
  const filteredAnbiams = Array.isArray(anbiams) && parishUser
    ? anbiams.filter(anbiam => anbiam.parishName === parishUser.name)
    : anbiams;

  // Filter families based on filteredAnbiams
  const filteredFamilies = Array.isArray(families)
    ? parishUser
      ? families.filter(family => 
          filteredAnbiams.some(anbiam => anbiam._id === family.anbiamId)
        )
      : families
    : [];

  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Family Card List
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="family table">
            <TableHead>
              <TableRow>
                <TableCell>Family Card Number</TableCell>
                <TableCell>Family Head Name</TableCell>
                <TableCell>Family Photo</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Google Map Link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredFamilies.map((family) => (
                <TableRow key={family._id}>
                  <TableCell>
                    <RouterLink to={`/family/${family._id}`}>
                      {family.familyCardNumber}
                    </RouterLink>
                  </TableCell>
                  <TableCell>{family.familyHeadName}</TableCell>
                  <TableCell>
                    {family.familyPhoto ? (
                      <img
                        src={family.familyPhoto}
                        alt={`${family.familyHeadName} Family`}
                        style={{ width: '100px', height: '100px' }}
                      />
                    ) : (
                      'No photo'
                    )}
                  </TableCell>
                  <TableCell>{family.phoneNumber || 'N/A'}</TableCell>
                  <TableCell>
                    {family.googleMapLink ? (
                      <Link href={family.googleMapLink} target="_blank" rel="noopener">
                        View on Google Maps
                      </Link>
                    ) : (
                      'No link'
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default FamilyList;
