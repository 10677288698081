import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Grid, Container, Typography, Button, Box } from '@mui/material';

import SelectParish from '../../Selecter/SelectParish';

const CommunionConfirmationForm = ({ parishUser }) => {
  const [formData, setFormData] = useState({
    name: '',
    sex: '',
    dateOfBaptism: '',
    fatherName: '',
    motherName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    googleMapsLink: '',
    communionDate: '',
    communionPlace: '',
    confirmationDate: '',
    confirmationPlace: '',
    godfatherName: '',
    godmotherName: '',
    blessedBy: '',
    authorizedBy: '',
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedParish, setSelectedParish] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };  

  const handleSelectParish = (parish) => {
    setSelectedParish(parish);
    setDialogOpen(false);
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the parishUserId is empty
    if (!parishUser?._id && !selectedParish?._id) {
      setDialogOpen(true); // Open the dialog if _id is empty
      return; // Stop execution to prevent submission
    }
  
  
    // Combine form data with parishUser data
    const submissionData = {
      ...formData,
      parishUserId: parishUser?._id || selectedParish?._id,
      parishUserName: parishUser?.name || selectedParish?.name,
    };
  
    try {
      const response = await fetch('/communion_confirmation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submissionData),
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log('Submission successful:', result);
        alert('Form submitted successfully!');
        // Optionally, clear the form or handle other UI updates here
      } else {
        const errorData = await response.json();
        console.error('Error during submission:', errorData);
        alert('Error submitting form: ' + (errorData.message || 'Unknown error'));
      }
    } catch (error) {
      console.error('Network error:', error);
      alert('Network error: ' + error.message);
    }
  };
  
  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Communion-Confirmation Register
      </Typography>
      <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Form Fields */}
          <Grid item xs={12}><TextField label="Name" name="name" onChange={handleChange} fullWidth /></Grid>
          <Grid item xs={12}><TextField label="Sex" name="sex" onChange={handleChange} fullWidth /></Grid>
          <Grid item xs={12}><TextField label="Date of Baptism" name="dateOfBaptism" type="date" onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth /></Grid>
          <Grid item xs={12}><TextField label="Father's Name" name="fatherName" onChange={handleChange} fullWidth /></Grid>
          <Grid item xs={12}><TextField label="Mother's Name" name="motherName" onChange={handleChange} fullWidth /></Grid>
          <Grid item xs={12}><TextField label="Address Line 1" name="addressLine1" onChange={handleChange} fullWidth /></Grid>
          <Grid item xs={12}><TextField label="Address Line 2" name="addressLine2" onChange={handleChange} fullWidth /></Grid>
          <Grid item xs={12}><TextField label="Address Line 3" name="addressLine3" onChange={handleChange} fullWidth /></Grid>
          <Grid item xs={12}><TextField label="Google Maps Link" name="googleMapsLink" onChange={handleChange} fullWidth /></Grid>
          <Grid item xs={12}><TextField label="Communion Date" name="communionDate" type="date" onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth /></Grid>
          <Grid item xs={12}><TextField label="Communion Place" name="communionPlace" onChange={handleChange} fullWidth /></Grid>
          <Grid item xs={12}><TextField label="Confirmation Date" name="confirmationDate" type="date" onChange={handleChange} InputLabelProps={{ shrink: true }} fullWidth /></Grid>
          <Grid item xs={12}><TextField label="Confirmation Place" name="confirmationPlace" onChange={handleChange} fullWidth /></Grid>
          <Grid item xs={12}><TextField label="Godfather Name" name="godfatherName" onChange={handleChange} fullWidth /></Grid>
          <Grid item xs={12}><TextField label="Godmother Name" name="godmotherName" onChange={handleChange} fullWidth /></Grid>
          <Grid item xs={12}><TextField label="Blessed By" name="blessedBy" onChange={handleChange} fullWidth /></Grid>
          <Grid item xs={12}><TextField label="Authorized By" name="authorizedBy" onChange={handleChange} fullWidth /></Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
      <SelectParish
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      onSelect={handleSelectParish}
    />
    </Container>
  );
};

export default CommunionConfirmationForm;
