import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const BaptismDetails = ({ open, onClose, baptism }) => {
  if (!baptism) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Baptism Details
        <IconButton 
          aria-label="close" 
          onClick={onClose} 
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1"><strong>Name:</strong> {baptism.name}</Typography>
        <Typography variant="body1"><strong>Sex:</strong> {baptism.sex}</Typography>
        <Typography variant="body1"><strong>Age:</strong> {baptism.age}</Typography>
        <Typography variant="body1"><strong>Date of Baptism:</strong> {baptism.baptismDate}</Typography>
        <Typography variant="body1"><strong>Birth Place:</strong> {baptism.birthPlace}</Typography>
        <Typography variant="body1"><strong>Father's Name:</strong> {baptism.fatherName}</Typography>
        <Typography variant="body1"><strong>Mother's Name:</strong> {baptism.motherName}</Typography>
        <Typography variant="body1"><strong>God Father's Name:</strong> {baptism.godFatherName}</Typography>
        <Typography variant="body1"><strong>God Mother's Name:</strong> {baptism.godMotherName}</Typography>
        <Typography variant="body1"><strong>Remarks:</strong> {baptism.remarks}</Typography>
        <Typography variant="body1"><strong>Blessed By:</strong> {baptism.blessedBy}</Typography>
        <Typography variant="body1"><strong>Authorized By:</strong> {baptism.authorizedBy}</Typography>
      </DialogContent>
    </Dialog>
  );
};

export default BaptismDetails;
