import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, MenuItem, Grid, Typography, Box, Container } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import SelectParish from '../../Selecter/SelectParish';

const BaptismForm = ({ parishUser }) => {
  const [formData, setFormData] = useState({
    name: '',
    sex: '',
    birthDate: null,
    birthPlace: '',
    baptismDate: null,
    baptismPlace: '',
    fatherName: '',
    motherName: '',
    godFatherName: '',
    godMotherName: '',
    remarks: '',
    blessedBy: '',
    authorizedBy: '',
    parishUserName: parishUser?.name || '', // Initialize with ParishUser name
    parishUserId: parishUser?._id || '',    // Initialize with ParishUser _id
  });

  console.log(parishUser);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedParish, setSelectedParish] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (name, date) => {
    setFormData({ ...formData, [name]: date });
  };

  const handleSelectParish = (parish) => {
    setSelectedParish(parish);
    setDialogOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if parishUserId is available
    if (!parishUser?._id && !selectedParish?._id) {
      console.log(`remember my son this is the anser${parishUser}`);
      setDialogOpen(true);
      return;
    }

    // Prepare submission data
    const submissionData = {
      ...formData,
      parishUserId: parishUser?._id || selectedParish?._id,
      parishUserName: parishUser?.name || selectedParish?.name,
    };

    try {
      const response = await fetch('/baptism', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submissionData),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Submission successful:', result);
        alert('Baptism record submitted successfully!');
      } else {
        const errorData = await response.json();
        console.error('Error during submission:', errorData);
        alert('Error submitting form: ' + (errorData.message || 'Unknown error'));
      }
    } catch (error) {
      console.error('Network error:', error);
      alert('Network error: ' + error.message);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="sm">
        <Box sx={{ p: 4 }}>
          <Typography variant="h4" align="center" gutterBottom>
            Baptism Record Form
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {/* Form Fields */}
              <Grid item xs={12}>
                <TextField fullWidth label="Name" name="name" value={formData.name} onChange={handleChange} />
              </Grid>
              <Grid item xs={12}>
                <TextField select fullWidth label="Sex" name="sex" value={formData.sex} onChange={handleChange}>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Birth Date"
                  value={formData.birthDate}
                  onChange={(date) => handleDateChange('birthDate', date)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Birth Place" name="birthPlace" value={formData.birthPlace} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Baptism Date"
                  value={formData.baptismDate}
                  onChange={(date) => handleDateChange('baptismDate', date)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Baptism Place" name="baptismPlace" value={formData.baptismPlace} onChange={handleChange} />
              </Grid>
              {/* Additional Fields */}
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Father's Name" name="fatherName" value={formData.fatherName} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Mother's Name" name="motherName" value={formData.motherName} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="God Father's Name" name="godFatherName" value={formData.godFatherName} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="God Mother's Name" name="godMotherName" value={formData.godMotherName} onChange={handleChange} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Remarks" name="remarks" value={formData.remarks} onChange={handleChange} multiline rows={3} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Blessed by" name="blessedBy" value={formData.blessedBy} onChange={handleChange} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Authorized by" name="authorizedBy" value={formData.authorizedBy} onChange={handleChange} />
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <Button type="submit" variant="contained" fullWidth color="primary">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
          <SelectParish open={dialogOpen} onClose={() => setDialogOpen(false)} onSelect={handleSelectParish} />
        </Box>
      </Container>
    </LocalizationProvider>
  );
};

export default BaptismForm;
