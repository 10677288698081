import React, { useEffect, useState } from 'react';
import {
  Container, Typography, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, FormControl, InputLabel, Select, MenuItem,
  TextField
} from '@mui/material';
import dayjs from 'dayjs';
import axios from 'axios';
import DeathDetails from '../Registers/Details/DeathDetails';

const DeathAnniversary = ({ parishUser }) => {
  const [deaths, setDeaths] = useState([]);
  const [filteredDeaths, setFilteredDeaths] = useState([]);
  const [selectedDeath, setSelectedDeath] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [filterType, setFilterType] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Fetch death data from the backend API
  useEffect(() => {
    const fetchDeaths = async () => {
      try {
        const response = await axios.get('/death'); // Replace with your API endpoint
        setDeaths(response.data);
      } catch (error) {
        console.error("Error fetching death data:", error);
      }
    };
    fetchDeaths();
  }, []);

  // Filter deaths based on selected filter type and dates
  useEffect(() => {
    let filtered = parishUser ? deaths.filter(death => death.parishUserId === parishUser._id) : deaths;
    
    const today = dayjs();
    const startOfWeek = today.startOf('week');
    const endOfWeek = today.endOf('week');
    const startOfMonth = today.startOf('month');
    const endOfMonth = today.endOf('month');

    switch (filterType) {
      case 'thisWeek':
        filtered = filtered.filter(death => dayjs(death.deathDate).isBetween(startOfWeek, endOfWeek, null, '[]'));
        break;
      case 'upcomingWeek':
        filtered = filtered.filter(death => dayjs(death.deathDate).isBetween(endOfWeek.add(1, 'day'), endOfWeek.add(7, 'days'), null, '[]'));
        break;
      case 'previousWeek':
        filtered = filtered.filter(death => dayjs(death.deathDate).isBetween(startOfWeek.subtract(7, 'days'), endOfWeek.subtract(7, 'days'), null, '[]'));
        break;
      case 'currentMonth':
        filtered = filtered.filter(death => dayjs(death.deathDate).isBetween(startOfMonth, endOfMonth, null, '[]'));
        break;
      case 'previousMonth':
        filtered = filtered.filter(death => dayjs(death.deathDate).isBetween(startOfMonth.subtract(1, 'month'), endOfMonth.subtract(1, 'month'), null, '[]'));
        break;
      case 'nextMonth':
        filtered = filtered.filter(death => dayjs(death.deathDate).isBetween(startOfMonth.add(1, 'month'), endOfMonth.add(1, 'month'), null, '[]'));
        break;
      case 'custom':
        if (startDate && endDate) {
          filtered = filtered.filter(death => dayjs(death.deathDate).isBetween(dayjs(startDate), dayjs(endDate), null, '[]'));
        }
        break;
      default:
        break;
    }
    setFilteredDeaths(filtered);
  }, [filterType, startDate, endDate, parishUser, deaths]);

  // Calculate age on earth and age in heaven
  const calculateAge = (birthDate, deathDate) => {
    return dayjs(deathDate).diff(dayjs(birthDate), 'year');
  };

  const calculateAgeInHeaven = (deathDate) => {
    const anniversaryThisYear = dayjs().year(dayjs(deathDate).year()).set('month', dayjs(deathDate).month()).set('date', dayjs(deathDate).date());
    return anniversaryThisYear.diff(dayjs(deathDate), 'year');
  };

  // Handle row click to open the dialog
  const handleRowClick = (death) => {
    setSelectedDeath(death);
    setIsDialogOpen(true);
  };

  // Handle closing the dialog
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedDeath(null);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        List of Tomb Records
      </Typography>

      <FormControl variant="outlined" style={{ marginBottom: 20, minWidth: 200 }}>
        <InputLabel>Date Filter</InputLabel>
        <Select
          value={filterType}
          onChange={(e) => setFilterType(e.target.value)}
          label="Date Filter"
        >
          <MenuItem value="thisWeek">This Week</MenuItem>
          <MenuItem value="upcomingWeek">Upcoming Week</MenuItem>
          <MenuItem value="previousWeek">Previous Week</MenuItem>
          <MenuItem value="currentMonth">Current Month</MenuItem>
          <MenuItem value="previousMonth">Previous Month</MenuItem>
          <MenuItem value="nextMonth">Next Month</MenuItem>
          <MenuItem value="custom">Custom</MenuItem>
        </Select>
      </FormControl>

      {filterType === 'custom' && (
        <div style={{ display: 'flex', gap: '1rem', marginBottom: 20 }}>
          <TextField
            label="Start Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <TextField
            label="End Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Tomb Number</strong></TableCell>
              <TableCell><strong>Name</strong></TableCell>
              <TableCell><strong>Date of Birth</strong></TableCell>
              <TableCell><strong>Age on Earth</strong></TableCell>
              <TableCell><strong>Date of Death</strong></TableCell>
              <TableCell><strong>Age in Heaven</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDeaths.map((death, index) => (
              <TableRow key={index} onClick={() => handleRowClick(death)} style={{ cursor: 'pointer' }}>
                <TableCell>{death.tombNumber}</TableCell>
                <TableCell>{death.name}</TableCell>
                <TableCell>{death.dob}</TableCell>
                <TableCell>{calculateAge(death.dob, death.deathDate)}</TableCell>
                <TableCell>{death.deathDate}</TableCell>
                <TableCell>{calculateAgeInHeaven(death.deathDate)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Render the dialog component */}
      <DeathDetails
        death={selectedDeath}
        open={isDialogOpen}
        onClose={handleCloseDialog}
      />
    </Container>
  );
};

export default DeathAnniversary;
