import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LogoutIcon from '@mui/icons-material/Logout';
import AddIcon from '@mui/icons-material/Add';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import BookIcon from '@mui/icons-material/Book';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import CakeIcon from '@mui/icons-material/Cake';

// Importing components for different menu options
import Donation from './Menu/Donations';
import FamilyDetails from './Menu/FamilyDetails';
import Notices from './Menu/Notices';
import Schedule from './Menu/Schedule';
import Subscription from './Menu/Subscription';
import MassIntention from './Menu/MassIntention';

import BirthdayList from './Notice_Shedule/BirthdayList';
import DeathAnniversary from './Notice_Shedule/DeathAnniversary';

// Admin components
import AdminDonations from './Admin/AdminDonations';
import AddFamily from './Admin/AddFamily';
import AdminFamilyDetails from './Admin/AdminFamilyDetails';
import AdminNotices from './Admin/AdminNotices';
import AdminSchedule from './Admin/AdminSchedule';
import AdminSubscription from './Admin/AdminSubscription';
import AdminMassIntention from './Admin/AdminMassIntention';
import AddDevotee from './Admin/AddDevotee';
import OurDevotees from './Admin/OurDevotees';
import FamilyCardList from './Admin/FamilyCardList';

// Operator components
import DevoteeForm from './Fourms/DevoteeForm';
import OpOurDevotees from './List/OurDevotees';
import FamilyForm from './Fourms/FamilyForm';
import OpFamilyCardList from './List/FamilyCardList';
import CreateAnbiam from './Fourms/CreateAnbiam';
import AnbiamList from './List/AnbiamList';
import CreateSubstation from './Fourms/CreateSubstation';
import SubstationList from './List/SubstationList';
import CreateParish from './Fourms/CreateParish';
import ParishList from './List/ParishList';
import CreateVicaret from './Fourms/CreateVicaret';
import VicaretList from './List/VicarateList';


//register components
import BaptismForm from './Registers/Form/BaptismForm'
import BaptismList from './Registers/List/BaptismList'
import CommunionConfirmationForm from './Registers/Form/CommunionConfirmationForm'
import CommunionConfirmationList from './Registers/List/CommunionConfirmationList'
import MarriageRegisterFrom from './Registers/Form/MarriageRegisterForm'
import MarriageList from './Registers/List/MarriagesList'
import DeathForm from './Registers/Form/DeathForm'
import DeathList from './Registers/List/DeathList'

// Parish components
import DashBoard from './DashBoards/DashBoard';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function ChurchApp({ userRole, onLogout,parishUser }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(
    userRole === 'admin'
      ? 'Admin Donations'
      : userRole === 'op'
      ? 'Dashbord Profile'
      : userRole === 'parish'
      ? 'Dashbord Profile'
      : 'Donation'
  );

  console.log(parishUser);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  // Menus based on user roles
  /**const menuConfigold = {
    user: [
      { text: 'Donation', icon: <FavoriteIcon /> },
      { text: 'Family Details', icon: <FamilyRestroomIcon /> },
      { text: 'Notices', icon: <AnnouncementIcon /> },
      { text: 'Schedule', icon: <CalendarTodayIcon /> },
      { text: 'Subscription', icon: <CalendarTodayIcon /> },
      { text: 'Mass Intention', icon: <CalendarTodayIcon /> },
    ],
    admin: [
      { text: 'Admin Donations', icon: <FavoriteIcon /> },
      { text: 'Add Family', icon: <AddIcon /> },
      { text: 'Admin Family Details', icon: <FamilyRestroomIcon /> },
      { text: 'Admin Notices', icon: <AnnouncementIcon /> },
      { text: 'Admin Schedule', icon: <CalendarTodayIcon /> },
      { text: 'Admin Subscription', icon: <CalendarTodayIcon /> },
      { text: 'Admin Mass Intention', icon: <CalendarTodayIcon /> },
      { text: 'Add Devotee', icon: <AddIcon /> },
      { text: 'Our Devotees', icon: <CalendarTodayIcon /> },
      { text: 'Family Card List', icon: <FamilyRestroomIcon /> },
    ],
    op: [
      { text: 'Family Card', icon: <AddIcon /> },
      { text: 'Op Family Card List', icon: <FamilyRestroomIcon /> },
      { text: 'New Devotee', icon: <AddIcon /> },
      { text: 'Op Our Devotees', icon: <CalendarTodayIcon /> },
      { text: 'Create Parish', icon: <AddIcon /> },
      { text: 'Parish List', icon: <FavoriteIcon /> },
      { text: 'Create Sub-Station', icon: <AddIcon /> },
      { text: 'Substation List', icon: <FavoriteIcon /> },
      { text: 'Create Anbiam', icon: <AddIcon /> },
      { text: 'Anbiam List', icon: <FavoriteIcon /> },
      { text: 'Create Vicaret', icon: <AddIcon /> },
      { text: 'Vicaret List', icon: <FavoriteIcon /> },
    ],
    parish: [
      { text: 'Dashbord Profile', icon: <FamilyRestroomIcon /> },
      { text: 'Anbiam List', icon: <FamilyRestroomIcon /> },
      { text: 'Op Family Card List', icon: <FamilyRestroomIcon /> },
      { text: 'Op Our Devotees', icon: <CalendarTodayIcon /> },
      { text: 'Family Card', icon: <AddIcon /> },
      { text: 'New Devotee', icon: <AddIcon /> },
    ],
  };**/

  const menuConfig = {
    user: [
      
    ],
    admin: [
      
    ],
    op: [
      {
        section: 'Donations & Families',
        items: [
          { text: 'Admin Donations', icon: <FavoriteIcon /> },
        ],
      },
      {
        section: 'Notices & Scheduling',
        items: [
          { text: 'Admin Notices', icon: <AnnouncementIcon /> },
          { text: 'Admin Schedule', icon: <CalendarTodayIcon /> },
          { text: 'Admin Subscription', icon: <CalendarTodayIcon /> },
          { text: 'Admin Mass Intention', icon: <CalendarTodayIcon /> },
          { text: 'Birthday List', icon: <CakeIcon />},
          { text: 'Death Anniversary', icon: <CakeIcon />},
        ],
      },
      {
        section: 'Parish Families',
        items: [
          { text: 'Family Card', icon: <AddIcon /> },
          { text: 'Op Family Card List', icon: <FamilyRestroomIcon /> },
          { text: 'New Devotee', icon: <AddIcon /> },
          { text: 'Op Our Devotees', icon: <CalendarTodayIcon /> },
        ],
      },
      {
        section: 'Parish Area',
        items: [
          { text: 'Create Sub-Station', icon: <AddIcon /> },
          { text: 'Substation List', icon: <FavoriteIcon /> },
          { text: 'Create Anbiam', icon: <AddIcon /> },
          { text: 'Anbiam List', icon: <FavoriteIcon /> },
        ],
      },
      {
        section: 'Vicarate',
        items: [
          { text: 'Create Parish', icon: <AddIcon /> },
          { text: 'Parish List', icon: <FavoriteIcon /> },
          { text: 'Create Vicaret', icon: <AddIcon /> },
          { text: 'Vicaret List', icon: <FavoriteIcon /> },

        ],
      },
      {
        section: 'Register',
        items: [
          { text: 'Baptisim Register',icon: <BookIcon/>},
          { text: 'Baptisim Form', icon: <AddIcon /> },
          { text: 'Comunion/Comformation Register',icon: <BookIcon/>},
          { text: 'Comunion/Comformation Form', icon: <AddIcon /> },
          { text: 'Marriage Register',icon: <BookIcon/>},
          { text: 'Marriage Form', icon: <AddIcon /> },
          { text: 'Death Register',icon: <BookIcon/>},
          { text: 'Death Form', icon: <AddIcon /> },
        ],
      },
    ],
    parish: [
      {
        section: 'Donations & Families',
        items: [
          { text: 'Admin Donations', icon: <FavoriteIcon /> },
        ],
      },
      {
        section: 'Notices & Scheduling',
        items: [
          { text: 'Admin Notices', icon: <AnnouncementIcon /> },
          { text: 'Admin Schedule', icon: <CalendarTodayIcon /> },
          { text: 'Admin Subscription', icon: <CalendarTodayIcon /> },
          { text: 'Admin Mass Intention', icon: <CalendarTodayIcon /> },
          { text: 'Birthday List', icon: <CakeIcon />},
          { text: 'Death Anniversary', icon: <CakeIcon />},
        ],
      },
      {
        section: 'Parish Families',
        items: [
          { text: 'Family Card', icon: <AddIcon /> },
          { text: 'Op Family Card List', icon: <FamilyRestroomIcon /> },
          { text: 'New Devotee', icon: <AddIcon /> },
          { text: 'Op Our Devotees', icon: <CalendarTodayIcon /> },
        ],
      },
      {
        section: 'Parish Area',
        items: [
          { text: 'Substation List', icon: <FavoriteIcon /> },
          { text: 'Anbiam List', icon: <FavoriteIcon /> },
        ],
      },
      {
        section: 'Register',
        items: [
          { text: 'Baptisim Register',icon: <BookIcon/>},
          { text: 'Baptisim Form', icon: <AddIcon /> },
          { text: 'Comunion/Comformation Register',icon: <BookIcon/>},
          { text: 'Comunion/Comformation Form', icon: <AddIcon /> },
          { text: 'Marriage Register',icon: <BookIcon/>},
          { text: 'Marriage Form', icon: <AddIcon /> },
          { text: 'Death Register',icon: <BookIcon/>},
          { text: 'Death Form', icon: <AddIcon /> },
        ],
      },
    ],
  };
  

  const menu = menuConfig[userRole] || menuConfig.user;

  // Render the selected component based on the selected menu
  const renderSelectedMenu = () => {
    switch (selectedMenu) {
      case 'Dashboard':
        return <DashBoard parishUser={parishUser} />; // Render the Dashboard component
      case 'Donation':
        return <Donation />;
      case 'Family Details':
        return <FamilyDetails />;
      case 'Notices':
        return <Notices />;
      case 'Schedule':
        return <Schedule />;
      case 'Subscription':
        return <Subscription />;
      case 'Mass Intention':
        return <MassIntention />;

      case 'Birthday List': //TODO parishUser
        return <BirthdayList />;
        case 'Death Anniversary': //TODO parishUser
        return <DeathAnniversary />;

      case 'Admin Donations':
        return <AdminDonations />;
      case 'Add Family':
        return <AddFamily />;
      case 'Admin Family Details':
        return <AdminFamilyDetails />;
      case 'Admin Notices':
        return <AdminNotices />;
      case 'Admin Schedule':
        return <AdminSchedule />;
      case 'Admin Subscription':
        return <AdminSubscription />;
      case 'Admin Mass Intention':
        return <AdminMassIntention />;
      case 'Add Devotee':
        return <AddDevotee />;
      case 'Our Devotees':
        return <OurDevotees />;
      case 'Family Card List':
        return <FamilyCardList />;

      case 'Family Card':
        return <FamilyForm parishUser={parishUser}/>;
      case 'Op Family Card List':
        return <OpFamilyCardList parishUser={parishUser}/>;
      case 'New Devotee':
        return <DevoteeForm parishUser={parishUser}/>;
      case 'Op Our Devotees':
        return <OpOurDevotees parishUser={parishUser}/>;
      case 'Create Parish':
        return <CreateParish />;
      case 'Parish List':
        return <ParishList />;
      case 'Create Sub-Station':
        return <CreateSubstation />;
      case 'Substation List':
        return <SubstationList parishUser={parishUser}/>;
      case 'Create Anbiam':
        return <CreateAnbiam />;
      case 'Anbiam List':
        return <AnbiamList parishUser={parishUser}/>;
      case 'Create Vicaret':
        return <CreateVicaret />;
      case 'Vicaret List':
        return <VicaretList />;

      // pass the parishUser 
      case 'Marriage Form':
        return <MarriageRegisterFrom parishUser={parishUser}/>;
      case 'Marriage Register':
        return <MarriageList parishUser={parishUser}/>;
      case 'Comunion/Comformation Form':
        return <CommunionConfirmationForm parishUser={parishUser}/>
      case 'Comunion/Comformation Register':
        return <CommunionConfirmationList parishUser={parishUser}/>
      case 'Baptisim Form':
        return <BaptismForm parishUser={parishUser}/>;
      case 'Baptisim Register':
        return <BaptismList parishUser={parishUser}/>;
      case 'Death Form':
        return <DeathForm parishUser={parishUser}/>;
      case 'Death Register':
        return <DeathList parishUser={parishUser}/>;

      case 'Dashbord Profile':
        return <DashBoard parishUser={parishUser}/>;

      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Church App
          </Typography>
          <Button color="inherit" sx={{ marginLeft: 'auto' }} onClick={onLogout}>
            <LogoutIcon /> Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />

        {/* Dashboard Menu Item at the Top */}
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => setSelectedMenu('Dashboard')}>
              <ListItemIcon><DashboardIcon /></ListItemIcon> {/* Choose a suitable icon */}
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          </ListItem>
        </List>

        <Divider />

        {/* Render Menu Sections based on User Role */}
        {menu.map((section) => (
          <Accordion key={section.section} elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${section.section}-content`}
              id={`${section.section}-header`}
            >
              <Typography variant="subtitle1">{section.section}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {section.items.map(({ text, icon }) => (
                  <ListItem key={text} disablePadding>
                    <ListItemButton onClick={() => setSelectedMenu(text)}>
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
            <Divider />
          </Accordion>
        ))}
      </Drawer>

      <Main open={open}>
        <DrawerHeader />
        {renderSelectedMenu()}
      </Main>
    </Box>
  );
}
