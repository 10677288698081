import React, { useState } from 'react';
import axios from 'axios';
import { Container, TextField, Grid, Typography, Divider, Button } from '@mui/material';

import SelectParish from '../../Selecter/SelectParish';

const MarriageRegisterForm = ({ parishUser }) => {
  const [formData, setFormData] = useState({
    placeName: '',
    marriageDate: '',
    brideName: '',
    brideDob: '',
    brideMaritalStatus: '',
    brideAddressLine1: '',
    brideAddressLine2: '',
    brideAddressLine3: '',
    brideMapLink: '',
    brideFatherName: '',
    brideMotherName: '',
    brideWitnessName: '',
    brideWitnessAddressLine1: '',
    brideWitnessAddressLine2: '',
    brideWitnessAddressLine3: '',
    brideWitnessMapLink: '',
    brideBanns: '',
    brideImpediment: '',
    groomName: '',
    groomDob: '',
    groomMaritalStatus: '',
    groomAddressLine1: '',
    groomAddressLine2: '',
    groomAddressLine3: '',
    groomMapLink: '',
    groomFatherName: '',
    groomMotherName: '',
    groomWitnessName: '',
    groomWitnessAddressLine1: '',
    groomWitnessAddressLine2: '',
    groomWitnessAddressLine3: '',
    groomWitnessMapLink: '',
    groomBanns: '',
    groomImpediment: '',
  });

  //TODO address line 1 required
  //TODO add witness father name + address line 1 reuiqred
  //TODO change bride to BRidegrrom  then chnage groom to bride
  //TODO add check box for "Marrage','REctification" (toggle) default marrage

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedParish, setSelectedParish] = useState(null);


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectParish = (parish) => {
    setSelectedParish(parish);
    setDialogOpen(false);
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Check if the parishUserId is empty
    if (!parishUser?._id && !selectedParish?._id) {
      setDialogOpen(true); // Open the dialog if _Id is empty
      return; // Stop execution to prevent submission
    }
  
    // Prepare final data
    const finalData = {
      ...formData,
      parishUserId: parishUser?._id || selectedParish?._id,
      parishUserName: parishUser?.name || selectedParish?.name,
    };
  
    try {
      // Send the form data to the backend
      const response = await axios.post('/marriage', finalData);
      console.log('Submission successful:', response.data);
      // Handle successful submission (e.g., show a success message or clear the form)
    } catch (error) {
      console.error('Error submitting the form:', error);
      // Handle the error (e.g., show an error message)
    }
  };
  
  

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Marriage Registration
      </Typography>

      <Grid container spacing={3}>
        {/* General Details */}
        <Grid item xs={12}>
          <TextField label="Name of the Place" fullWidth required name="placeName" value={formData.placeName} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Date of Marriage" type="date" fullWidth InputLabelProps={{ shrink: true }} required name="marriageDate" value={formData.marriageDate} onChange={handleChange} />
        </Grid>

        {/* Bride's Details */}
        <Divider textAlign="left" style={{ width: '100%' }}>Bride's Details</Divider>
        <Grid item xs={12} sm={6}>
          <TextField label="Name of Bride" fullWidth required name="brideName" value={formData.brideName} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Date of Birth of Bride" type="date" fullWidth InputLabelProps={{ shrink: true }} required name="brideDob" value={formData.brideDob} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Marital Status of Bride" fullWidth required name="brideMaritalStatus" value={formData.brideMaritalStatus} onChange={handleChange} />
        </Grid>

        {/* Bride's Address */}
        <Grid item xs={12}>
          <Typography variant="h6">Bride's Address</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField label="Address Line 1" fullWidth name="brideAddressLine1" value={formData.brideAddressLine1} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Address Line 2" fullWidth name="brideAddressLine2" value={formData.brideAddressLine2} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Address Line 3" fullWidth name="brideAddressLine3" value={formData.brideAddressLine3} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Google Map Link" fullWidth name="brideMapLink" value={formData.brideMapLink} onChange={handleChange} />
        </Grid>

        {/* Bride's Family and Witness Details */}
        <Grid item xs={12} sm={6}>
          <TextField label="Bride's Father's Name" fullWidth required name="brideFatherName" value={formData.brideFatherName} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Bride's Mother's Name" fullWidth required name="brideMotherName" value={formData.brideMotherName} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Bride's Witness Name" fullWidth required name="brideWitnessName" value={formData.brideWitnessName} onChange={handleChange} />
        </Grid>

        {/* Bride's Witness Address */}
        <Grid item xs={12}>
          <Typography variant="h6">Bride's Witness Address</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField label="Address Line 1" fullWidth name="brideWitnessAddressLine1" value={formData.brideWitnessAddressLine1} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Address Line 2" fullWidth name="brideWitnessAddressLine2" value={formData.brideWitnessAddressLine2} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Address Line 3" fullWidth name="brideWitnessAddressLine3" value={formData.brideWitnessAddressLine3} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Google Map Link" fullWidth name="brideWitnessMapLink" value={formData.brideWitnessMapLink} onChange={handleChange} />
        </Grid>

        {/* Bride's Banns and Impediment */}
        <Grid item xs={12} sm={6}>
          <TextField label="Bride's Banns" fullWidth name="brideBanns" value={formData.brideBanns} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Bride's Impediment" fullWidth name="brideImpediment" value={formData.brideImpediment} onChange={handleChange} />
        </Grid>

        {/* Groom's Details */}
        <Divider textAlign="left" style={{ width: '100%' }}>Groom's Details</Divider>
        <Grid item xs={12} sm={6}>
          <TextField label="Name of Groom" fullWidth required name="groomName" value={formData.groomName} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Date of Birth of Groom" type="date" fullWidth InputLabelProps={{ shrink: true }} required name="groomDob" value={formData.groomDob} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Marital Status of Groom" fullWidth required name="groomMaritalStatus" value={formData.groomMaritalStatus} onChange={handleChange} />
        </Grid>

        {/* Groom's Address */}
        <Grid item xs={12}>
          <Typography variant="h6">Groom's Address</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField label="Address Line 1" fullWidth name="groomAddressLine1" value={formData.groomAddressLine1} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Address Line 2" fullWidth name="groomAddressLine2" value={formData.groomAddressLine2} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Address Line 3" fullWidth name="groomAddressLine3" value={formData.groomAddressLine3} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Google Map Link" fullWidth name="groomMapLink" value={formData.groomMapLink} onChange={handleChange} />
        </Grid>

        {/* Groom's Family and Witness Details */}
        <Grid item xs={12} sm={6}>
          <TextField label="Groom's Father's Name" fullWidth required name="groomFatherName" value={formData.groomFatherName} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Groom's Mother's Name" fullWidth required name="groomMotherName" value={formData.groomMotherName} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Groom's Witness Name" fullWidth required name="groomWitnessName" value={formData.groomWitnessName} onChange={handleChange} />
        </Grid>

        {/* Groom's Witness Address */}
        <Grid item xs={12}>
          <Typography variant="h6">Groom's Witness Address</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField label="Address Line 1" fullWidth name="groomWitnessAddressLine1" value={formData.groomWitnessAddressLine1} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Address Line 2" fullWidth name="groomWitnessAddressLine2" value={formData.groomWitnessAddressLine2} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Address Line 3" fullWidth name="groomWitnessAddressLine3" value={formData.groomWitnessAddressLine3} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Google Map Link" fullWidth name="groomWitnessMapLink" value={formData.groomWitnessMapLink} onChange={handleChange} />
        </Grid>

        {/* Groom's Banns and Impediment */}
        <Grid item xs={12} sm={6}>
          <TextField label="Groom's Banns" fullWidth name="groomBanns" value={formData.groomBanns} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Groom's Impediment" fullWidth name="groomImpediment" value={formData.groomImpediment} onChange={handleChange} />
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
            Submit
          </Button>
        </Grid>
      </Grid>
      <SelectParish
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      onSelect={handleSelectParish}
    />
    </Container>
  );
};

export default MarriageRegisterForm;
