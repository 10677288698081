import React, { useState } from 'react';
import axios from 'axios';
import { Container, TextField, Grid, Typography, Divider, Button } from '@mui/material';
import SelectParish from '../../Selecter/SelectParish';

const DeathForm = ({ parishUser }) => {
  const [formData, setFormData] = useState({
    tombNumber: '',
    name: '',
    dob: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    mapLink: '',
    fatherName: '',
    motherName: '',
    spouseName: '',
    deathPlace: '',
    deathDate: '',
    burialPlace: '',
    burialDate: '',
    ministry: '',
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedParish, setSelectedParish] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectParish = (parish) => {
    setSelectedParish(parish);
    setDialogOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if parishUserId is empty, open the parish selector dialog
    if (!parishUser?._id && !selectedParish?._id) {
      setDialogOpen(true);
      return;
    }

    // Prepare final data with selected parish details
    const finalData = {
      ...formData,
      parishUserId: parishUser?._id || selectedParish?._id,
      parishUserName: parishUser?.name || selectedParish?.name,
    };

    try {
      const response = await axios.post('/death', finalData);
      console.log('Submission successful:', response.data);
      // Reset form after successful submission
      setFormData({
        tombNumber: '',
        name: '',
        dob: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        mapLink: '',
        fatherName: '',
        motherName: '',
        spouseName: '',
        deathPlace: '',
        deathDate: '',
        burialPlace: '',
        burialDate: '',
        ministry: '',
      });
      setSelectedParish(null);
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Tomb Record Registration
      </Typography>

      <Grid container spacing={3}>
        {/* Tomb Details */}
        <Grid item xs={12}>
          <TextField label="Tomb Number" fullWidth required name="tombNumber" value={formData.tombNumber} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Name" fullWidth required name="name" value={formData.name} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Date of Birth" type="date" fullWidth InputLabelProps={{ shrink: true }} required name="dob" value={formData.dob} onChange={handleChange} />
        </Grid>

        <Divider textAlign="left" style={{ width: '100%' }}>Address</Divider>
        {/* Address Section */}
        <Grid item xs={12}>
          <TextField label="Address Line 1" fullWidth name="addressLine1" value={formData.addressLine1} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Address Line 2" fullWidth name="addressLine2" value={formData.addressLine2} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Address Line 3" fullWidth name="addressLine3" value={formData.addressLine3} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Google Map Link" fullWidth name="mapLink" value={formData.mapLink} onChange={handleChange} />
        </Grid>

        <Divider textAlign="left" style={{ width: '100%' }}>Family Details</Divider>
        {/* Family Details Section */}
        <Grid item xs={12}>
          <TextField label="Father's Name" fullWidth required name="fatherName" value={formData.fatherName} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Mother's Name" fullWidth required name="motherName" value={formData.motherName} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Spouse's Name" fullWidth required name="spouseName" value={formData.spouseName} onChange={handleChange} />
        </Grid>

        <Divider textAlign="left" style={{ width: '100%' }}>Death & Burial Details</Divider>
        {/* Death Details Section */}
        <Grid item xs={12}>
          <TextField label="Death Place" fullWidth required name="deathPlace" value={formData.deathPlace} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Death Date" type="date" fullWidth InputLabelProps={{ shrink: true }} required name="deathDate" value={formData.deathDate} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Burial Place" fullWidth required name="burialPlace" value={formData.burialPlace} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Burial Date" type="date" fullWidth InputLabelProps={{ shrink: true }} required name="burialDate" value={formData.burialDate} onChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Ministry" fullWidth required name="ministry" value={formData.ministry} onChange={handleChange} />
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
            Submit
          </Button>
        </Grid>
      </Grid>

      {/* Parish Selection Dialog */}
      <SelectParish
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSelect={handleSelectParish}
      />
    </Container>
  );
};

export default DeathForm;
