import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Grid } from '@mui/material';

const CommunionConfirmationDetails = ({ open, onClose, communionConfirmation }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Communion and Confirmation Details</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Name:</strong> {communionConfirmation.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Sex:</strong> {communionConfirmation.sex}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Date of Baptism:</strong> {communionConfirmation.dateOfBaptism}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Father's Name:</strong> {communionConfirmation.fatherName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Mother's Name:</strong> {communionConfirmation.motherName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Address Line 1:</strong> {communionConfirmation.addressLine1}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Address Line 2:</strong> {communionConfirmation.addressLine2}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Address Line 3:</strong> {communionConfirmation.addressLine3}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Google Maps Link:</strong> <a href={communionConfirmation.googleMapsLink} target="_blank" rel="noopener noreferrer">{communionConfirmation.googleMapsLink}</a></Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Communion Date:</strong> {communionConfirmation.communionDate}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Communion Place:</strong> {communionConfirmation.communionPlace}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Confirmation Date:</strong> {communionConfirmation.confirmationDate}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Confirmation Place:</strong> {communionConfirmation.confirmationPlace}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Godfather's Name:</strong> {communionConfirmation.godfatherName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Godmother's Name:</strong> {communionConfirmation.godmotherName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Blessed By:</strong> {communionConfirmation.blessedBy}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1"><strong>Authorized By:</strong> {communionConfirmation.authorizedBy}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommunionConfirmationDetails;
