import React, { useState } from 'react';
import { Card, Button, Container, Row, Col, ListGroup, Tooltip, OverlayTrigger, Modal, Image } from 'react-bootstrap';
import { FaStar, FaCheck, FaEye, FaUsers, FaPlus } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import './Dashboard.css'; // Updated CSS

const DashBoard = ({ parishUser }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');

  if (!parishUser) {
    return <div className="text-center my-4">Loading...</div>;
  }

  const { name, fatherName, subStations = [], anbiam = [], families = [], devotees = [], vicarateName } = parishUser;

  const handleOpenModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);

  const renderModalContent = () => {
    const items = {
      anbiam: anbiam.map((item) => item.anbiamName),
      subStations: subStations.map((item) => item.name),
      families: families.map((item) => item.familyName),
      devotees: devotees.map((item) => item.name),
    }[modalType] || [];

    return items.length ? (
      <ListGroup variant="flush">
        {items.map((item, index) => (
          <ListGroup.Item key={index} className="animate__animated animate__fadeIn">
            {item}
          </ListGroup.Item>
        ))}
      </ListGroup>
    ) : (
      <p>No items available</p>
    );
  };

  const MetricCard = ({ label, count, icon, type, bgColor }) => (
    <Col xs={6} md={3} className="mb-3">
      <OverlayTrigger overlay={<Tooltip>View {label}</Tooltip>}>
        <Card 
          onClick={() => handleOpenModal(type)}
          className="metric-card animate__animated animate__bounceIn"
          style={{ backgroundColor: bgColor, cursor: 'pointer' }}
        >
          <Card.Body>
            <h6>{label}</h6>
            <h3>{count}</h3>
            <div>{icon}</div>
          </Card.Body>
        </Card>
      </OverlayTrigger>
    </Col>
  );

  const ActionButton = ({ label, icon, onClick }) => (
    <Col xs={6} md={3} className="mb-3">
      <Button variant="outline-primary" className="w-100 animate__animated animate__fadeInUp" onClick={onClick}>
        {icon} {label}
      </Button>
    </Col>
  );

  return (
    <Container fluid className="dashboard-container py-4">
      {/* Header Section */}
      <Row className="header-section mb-4 animate__animated animate__fadeInDown">
        <Col md={6} className="p-3">
          <h2>Welcome Fr. {fatherName || 'Not Available'} to {name}</h2>
          <p><strong>Vicarate:</strong> {vicarateName}</p>
        </Col>
        <Col md={3} className="text-right">
          <Image src="path/to/profile.jpg" roundedCircle fluid alt="Profile" />
        </Col>
      </Row>

      {/* Metric Cards */}
      <Row className="text-center mb-4">
        {[
          { label: 'Anbiam', count: anbiam.length, icon: <FaStar />, type: 'anbiam', bgColor: '#FFD700' },
          { label: 'Sub-Stations', count: subStations.length, icon: <FaCheck />, type: 'subStations', bgColor: '#9370DB' },
          { label: 'Families', count: families.length, icon: <FaUsers />, type: 'families', bgColor: '#FFB6C1' },
          { label: 'Devotees', count: devotees.length, icon: <FaEye />, type: 'devotees', bgColor: '#D8BFD8' },
        ].map((metric, index) => (
          <MetricCard key={index} {...metric} />
        ))}
      </Row>

      {/* Modal for List Details */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{modalType} Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderModalContent()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Action Buttons */}
      <Row className="text-center">
        {[
          { label: 'Add Devotee', icon: <FaPlus />, onClick: () => alert('Add Devotee clicked!') },
          { label: 'Add Family', icon: <FaPlus />, onClick: () => alert('Add Family clicked!') },
          { label: 'Baptism Register', onClick: () => alert('Baptism Register clicked!') },
          { label: 'Communion Register', onClick: () => alert('Communion Register clicked!') },
          { label: 'Confirmation Register', onClick: () => alert('Confirmation Register clicked!') },
          { label: 'Marriage Register', onClick: () => alert('Marriage Register clicked!') },
          { label: 'Death Register', onClick: () => alert('Death Register clicked!') },
        ].map((action, index) => (
          <ActionButton key={index} {...action} />
        ))}
      </Row>
    </Container>
  );
};

export default DashBoard;
